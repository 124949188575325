import React from "react"

import Layout from "../components/layout/layout"
import { NotFound } from "../components/not-found/not-found"

const NotFoundPage = () => (
  <Layout title="Not Found">
    <NotFound />
  </Layout>
)

export default NotFoundPage
