import React, { Component } from "react"

import styles from "./not-found.module.scss"

export class NotFound extends Component {
  render() {
    return (
      <section className={styles.notFound}>
        <p className={styles.notFoundText}>
          <span>404</span>
          <span className={styles.notFoundOoops}>Ooops!</span>
        </p>
        <h2 className={styles.sorryMessage}>Sorry, page not found!</h2>
        <p>
          <a className={`btn btn-secondary ${styles.button}`} href="/">
            Back To Home
          </a>
        </p>
      </section>
    )
  }
}
